import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger);// eslint-disable-line
gsap.registerPlugin(SplitText);// eslint-disable-line




export default function scrlAct() {

  // 開発時にはコメントアウト
  gsap.config({
    nullTargetWarn: false,
  });


  // コードが全て読み込まれた後(DOMが構築後)に処理が開始
  document.addEventListener("DOMContentLoaded", function(){

    //mv
    const MvAni = gsap.timeline();
    MvAni.from(".ani--obi-01", {
      opacity: 1,
      scaleY: 0,
      transformOrigin:"0% 100%",
      duration: .5,
      delay: 1.2
    })
      .from(".ani--obi-02", {
        opacity: 1,
        scaleY: 0,
        transformOrigin:"0% 100%",
        duration: .5,
        delay: 0
      }, '-=.4')
      .from(".ani--obi--p", {
        opacity: 1,
        scaleY: 0,
        transformOrigin:"0% 100%",
        duration: .5,
        delay: 0
      }, '-=0.4');


    //mv copy
    var tlQuote = gsap.timeline(),
      mySplitText = new SplitText("#quote", {type:"words,chars"}),
      chars = mySplitText.chars; //an array of all the divs that wrap each character

    gsap.set("#quote", {perspective: 400});

    tlQuote.from(chars, {duration: 1.8, opacity:0, scale:0, y:80, rotationX:180, transformOrigin:"0% 50% -50",  ease:"back", stagger: 0.01}, "+=2");

    var amySplitText = new SplitText("#random", {type:"chars, words"}),
      tlRandom = new gsap.timeline(),
      numChars = amySplitText.chars.length;

    for(var i = 0; i < numChars; i++){
      tlRandom.from(amySplitText.chars[i], 5, {opacity:0}, Math.random() * 5);
    }

    // colmovie
    gsap.utils.toArray(".js--colmovie").forEach(function(document) {
      const aniColMovieFig = document.querySelectorAll('.js--colmovie--fig');
      const aniColMovieDesc = document.querySelectorAll('.js--colmovie--desc');

      let tlSclTopColMovie = gsap.timeline({
        scrollTrigger: {
          trigger: document,
          start: "0% 50%",
        }
      });
      tlSclTopColMovie.from(aniColMovieFig, {
        autoAlpha: 0,
        x:100,
        duration: 1,
        ease: "power4.out"
      },"-=.5")
        .from(aniColMovieDesc, {
          autoAlpha: 0,
          y:100,
          duration: 1,
          stagger: {
            each: .2,
          },
          ease: "power4.out"
        },"-=.5");
    });

    // intouch
    gsap.utils.toArray(".js--intouch").forEach(function(document) {
      const aniIntouchBox = document.querySelectorAll('.js--intouch--box');
      const aniIntouchItem = document.querySelectorAll('.js--intouch--item');

      let tlSclTopIntouch = gsap.timeline({
        scrollTrigger: {
          trigger: document,
          start: "0% 50%",
        }
      });
      tlSclTopIntouch.from(aniIntouchBox, {
        autoAlpha: 0,
        y:200,
        duration: 1,
        ease: "power4.out"
      },"-=.5")
        .from(aniIntouchItem, {
          autoAlpha: 0,
          y:200,
          duration: 1,
          stagger: {
            each: .1,
          },
          ease: "power4.out"
        },"-=.5");
    });

    // service
    gsap.utils.toArray(".js--service").forEach(function(document) {
      const aniService = document.querySelectorAll('.js--service--item');

      let tlSclTopColMovie = gsap.timeline({
        scrollTrigger: {
          trigger: document,
          start: "0% 50%",
        }
      });
      tlSclTopColMovie.from(aniService, {
        autoAlpha: 0,
        y:200,
        duration: 2,
        stagger: {
          each: .2,
        },
        scale: 1.05,
        ease: "power4.out"
      },"-=.5");
    });

    // slide
    gsap.utils.toArray(".js--slide").forEach(function(document) {
      const aniTopSlideimg = document.querySelectorAll('.js--slide--row');

      let tlSclTopSlide = gsap.timeline({
        scrollTrigger: {
          trigger: document,
          start: "0% 50%",
        }
      });
      tlSclTopSlide.from(aniTopSlideimg, {
        autoAlpha: 0,
        y:400,
        duration: 3,
        scale: 1.05,
        ease: "power4.out"
      },"-=.5");
    });

    // logo
    gsap.utils.toArray(".js--fadeup").forEach(function(document) {
      const aniLogoimg = document.querySelectorAll('.js--fadeup--img');

      let tlSclTopLogo = gsap.timeline({
        scrollTrigger: {
          trigger: document,
          // scrub: true,
          start: "100% 100%",
          // end: "+=500",
          // markers: true
        }
      });
      tlSclTopLogo.from(aniLogoimg, {
        autoAlpha: 0,
        y:80,
        duration: 1,
        scale: 1.05,
        ease: "power4.out"
      },"-=.5");
    });

  });

}