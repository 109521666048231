import Swiper from 'swiper/bundle';


export default function slides() {
  // コードが全て読み込まれた後(DOMが構築後)に処理が開始
  document.addEventListener("DOMContentLoaded", function(){
    const slider = document.querySelectorAll('.js--feature');
    for (let i = 0; i < slider.length; i++) {
      const mySwiper = new Swiper(slider[i], {// eslint-disable-line
        loop: true,
        loopAdditionalSlides: 3,
        slidesPerView: 1.5,
        spaceBetween: 0,
        centeredSlides: true,
        centermode: true,
        autoplay: {
          delay: 0,
          reverseDirection: true
        },
        speed: 24000,
        // parallax: true,
        breakpoints: {
          // when window width is >= 376px
          429: {
            slidesPerView: 3,
            spaceBetween: 0
          }
        }
      });
    }

    const sliderRv = document.querySelectorAll('.js--feature--rv');
    for (let i = 0; i < slider.length; i++) {
      const mySwiperRv = new Swiper(sliderRv[i], {// eslint-disable-line
        loop: true,
        loopAdditionalSlides: 3,
        slidesPerView: 1.5,
        spaceBetween: 0,
        centeredSlides: true,
        centermode: true,
        autoplay: {
          delay: 0,
        },
        speed: 24000,
        // parallax: true,
        breakpoints: {
          // when window width is >= 376px
          429: {
            slidesPerView: 3,
            spaceBetween: 0
          }
        }
      });
    }
  });
}
